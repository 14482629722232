@import "~@angular/material/prebuilt-themes/indigo-pink.css";

iframe.rvid-cnt {
  height: 1px;
  width: 1px;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  border: none;
  z-index: -1;
}
.clearfix{
  clear:both;
}
.right-aligned-header > .mat-content {
  justify-content: space-between;  
}
.mat-content > mat-panel-title, .mat-content > mat-panel-description {
flex: 0 0 auto;
}