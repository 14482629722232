.question-card {
  box-shadow: none !important;
}
.question-title {
  font-style: italic;
  font-size: 1.3em;
  font-weight: bolder;
}

.question-text {
  font-size: 1.3em;
}
.form-control {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.form-button {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mat-drawer-content {
  overflow: hidden !important;
}

.mat-card-content {
  padding: 5px !important;
  line-height: 15px !important;
}

.mat-card-content.page {
  padding: 15px !important;
}

.mat-card .mat-card-header {
  padding: 0 !important;
}

br {
  display: block !important;
}
